<template>
  <div>
    <div class="flex-r">
      <div>
        <el-button class="mb20" type="primary" @click="exportData"
          >導出</el-button
        >
      </div>
    </div>
    <el-table :data="tableData.data" style="width: 100%">
      <el-table-column width="60" label="NO.">
        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column label="搜索內容">
        <template slot-scope="scope">{{ scope.row.title }}</template>
      </el-table-column>
      <el-table-column label="搜索次數">
        <template slot-scope="scope">{{ scope.row.total }}</template>
      </el-table-column>
    </el-table>
    <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
      <el-pagination
        background
        hide-on-single-page
        :page-size="tableData.per_page"
        :current-page="tableData.current_page"
        :total="tableData.total"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: [],
      filters: {
        start_time: '',
        end_time: '',
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loadList = true
      let res = await this.api.statistics.getGoodsSearchRank()
      if (res && res.code == 0) {
        this.tableData = res.data
        this.st = {
          eps_num: res.data.eps_num,
          clk_num: res.data.clk_num,
          chat_num: res.data.chat_num,
        }
      }
      this.loadList = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    exportData() {
      this.getExportData()
    },
    async getExportData() {
      let res = await this.api.statistics.exportGoodsSearchRank()
      if (res && res.code == 0) {
        var a = document.createElement('a')
        a.href = res.data
        document.body.append(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.c-echart {
  height: 300px;
  display: flex;
  justify-content: space-between;
  .c-cell {
    width: 49%;
  }
}
</style>
