<template>
  <div ref="main" class="m-main-wrap" v-loading="loading">
    <div ref="topDom"></div>
    <div :style="{ height: tableHeight + 'px' }">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="發佈商品數" name="tab1">
          <publishVue></publishVue>
        </el-tab-pane>
        <el-tab-pane label="在售商品數" name="tab2">
          <sellingVue></sellingVue>
        </el-tab-pane>
        <el-tab-pane label="商品點閱率" name="tab3">
          <clicksVue></clicksVue>
        </el-tab-pane>
        <el-tab-pane label="成交數據" name="tab4">
          <dealVue></dealVue>
        </el-tab-pane>
        <el-tab-pane label="搜索排行" name="tab5">
          <rankVue></rankVue>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { calConHeight } from '@/common/tool'
import publishVue from './components/publish.vue'
import sellingVue from './components/selling.vue'
import clicksVue from './components/clicks.vue'
import dealVue from './components/deal.vue'
import rankVue from './components/rank.vue'
export default {
  components: {
    publishVue,
    sellingVue,
    clicksVue,
    dealVue,
    rankVue,
  },
  data() {
    return {
      loading: false,
      tableHeight: 400,
      activeName: 'tab1',
    }
  },
  created() {
    this.activeName = this.$route.query.tab ?this.$route.query.tab: 'tab1'
  },
  mounted() {
    // this.showTime()
    this.$nextTick(() => {
      this.tableHeight = calConHeight(this)
    })
  },
  methods: {
    handleClick(active) {},
    getStDatas() {},
  },
}
</script>
<style scoped>
.m-main-wrap {
  overflow: auto;
  padding-bottom: 100px;
}
</style>
